import { CanActivateFn, Router } from '@angular/router'
import { inject } from '@angular/core'
import { UserDataService } from '@core/services/user-data/user-data.service'
import { RoleEnum } from '@core/api/model/roleEnum'
import { filter } from 'rxjs'
import { Me, WholesaleStatusEnum } from '@core/api'

export const WsModuleGuard: CanActivateFn = () => {
  const userDataService = inject(UserDataService)
  const router = inject(Router)

  return new Promise((resolve) => {
    userDataService.userData$.pipe(filter((userData): userData is Me => !!userData)).subscribe((meData) => {
      if (
        meData.role !== RoleEnum.Ws ||
        (meData.myWholesaleStatus !== WholesaleStatusEnum.Approved &&
          meData.myWholesaleStatus !== WholesaleStatusEnum.TemporaryClosed)
      ) {
        router.navigate(['ws', 'register', 'register-user']).then(() => {
          resolve(false)
        })
        return
      }
      resolve(true)
    })
  })
}

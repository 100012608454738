import { WsOutletComponent } from '@pages/ws-outlet/ws-outlet.component'
import { gtAuthDefaultGuard } from '@pages/gt-outlet/routes/const'
import { GtHasSelectWsGuard } from '@core/guards/gt/gt-has-select-ws.guard'
import { GtHasNotSelectWsGuard } from '@core/guards/gt/gt-has-not-select-ws.guard'
import { GtModuleGuard } from '@core/guards/gt/gt-module.guard'
import { GtMainWrapperComponent } from '@pages/gt-outlet/gt-main-wrapper/gt-main-wrapper.component'
import { GtNewDesignGuard } from '@core/guards/gt/gt-new-design.guard'
import { GtOldDesignGuard } from '@core/guards/gt/gt-old-design.guard'
import { AuthWsGuard } from '@core/guards/auth/ws/auth-ws.guard'
import { WsModuleGuard } from '@core/guards/ws/ws-module.guard'

export const routes = [
  {
    path: 'gt',
    children: [
      {
        path: 'home',
        canActivate: [...gtAuthDefaultGuard, GtHasNotSelectWsGuard],
        loadChildren: () => import('@pages/gt-outlet/routes/gt-management-outlet.route').then((m) => m.homeRoutes),
      },
      {
        path: '',
        canActivate: [...gtAuthDefaultGuard, GtHasSelectWsGuard, GtOldDesignGuard],
        component: GtMainWrapperComponent,
        loadChildren: () => import('@pages/gt-outlet/routes/gt-outlet.route').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () => import('@pages/gt-outlet/routes/gt-register-outlet.route').then((m) => m.registerRoutes),
      },
    ],
  },
  {
    path: 'ws',
    children: [
      {
        path: 'register',
        canActivate: [AuthWsGuard],
        loadChildren: () => import('@pages/ws-outlet/ws-outlet.route').then((m) => [...m.registerRoutes]),
      },
      {
        path: '',
        component: WsOutletComponent,
        canActivate: [WsModuleGuard],
        loadChildren: () => import('@pages/ws-outlet/ws-outlet.route').then((m) => [...m.routes]),
      },
    ],
  },
  {
    path: 'general-trade',
    canActivate: [GtModuleGuard, GtHasSelectWsGuard, GtNewDesignGuard],
    loadChildren: () => import('@features/general-trade/routes/general-trade.route').then((m) => m.routes),
  },
  {
    path: 'auth',
    loadChildren: () => import('@features/auth/routes/auth.route').then((m) => m.routes),
  },
  { path: '**', redirectTo: '/auth' },
]

import { Component } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CouponService } from '@core/services/coupon/coupon.service'
import { NavBarService } from '@shared/components/v2/core/nav-bar/nav-bar.service'

@Component({
  selector: 'app-gt-main-wrapper',
  imports: [RouterModule],
  template: '<router-outlet></router-outlet>',
  providers: [CouponService, NavBarService],
})
export class GtMainWrapperComponent {}

import { CanActivateFn, Router } from '@angular/router'
import { inject } from '@angular/core'
import { GtWsService } from '@core/services/gt-ws/gt-ws.service'
import { filter } from 'rxjs'
import { Me } from '@core/api'
import { UserDataService } from '@core/services/user-data/user-data.service'

export const GtHasNotSelectWsGuard: CanActivateFn = () => {
  const router = inject(Router)
  const gtWsService = inject(GtWsService)
  const userDataService = inject(UserDataService)

  return new Promise((resolve) => {
    userDataService.userData$.pipe(filter((userData): userData is Me => !!userData)).subscribe(() => {
      if (gtWsService.wsId) {
        router.navigate([''])
        resolve(false)
        return
      }
      resolve(true)
    })
  })
}

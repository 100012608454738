import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { GtWsService } from '@core/services/gt-ws/gt-ws.service'
import { UserDataService } from '@core/services/user-data/user-data.service'
import { filter } from 'rxjs'
import { Me } from '@core/api'

export const GtNewDesignGuard = () => {
  const router = inject(Router)
  const gtWsService = inject(GtWsService)
  const userDataService = inject(UserDataService)

  return new Promise((resolve) => {
    userDataService.userData$.pipe(filter((userData): userData is Me => !!userData)).subscribe((meData) => {
      const ws = meData.myGtWholesaleIdsShowNewDesign.find((ws) => ws['id'] === gtWsService.wsId)
      if (!ws?.['isShowNewDesign']) {
        router.navigate(['gt']).then(() => {
          resolve(false)
        })
      }
      resolve(true)
    })
  })
}

<app-confirm-modal buttonLabel="ยอมรับทั้งหมด" [visible]="!shownCookieConsentModal()" (confirmed)="handleConfirm()">
  <div class="flex justify-center mb-2">
    <img src="assets/images/cookies.svg" alt="cookie" width="60" height="60" />
  </div>
  <div class="font-sarabun text-center leading-6 -mb-2">
    เพื่อเพิ่มประสิทธิภาพ<br />
    และประสบการณ์ที่ดี ในการใช้งานเว็บไซต์<br />
    คุณสามารถเลือกตั้งค่าความยินยอม<br />
    การใช้คุกกี้ได้ โดยคลิก
    <button (click)="goToCookieSetting()" class="text-primary-blue-100 underline font-bold">ตั้งค่าคุกกี้</button>
  </div>
</app-confirm-modal>

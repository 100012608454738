import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { catchError, EMPTY, Observable, throwError } from 'rxjs'
import { Injectable } from '@angular/core'
import { ToastService } from '@core/services/toast/toast.service'
import { ConfirmDialogService } from '@core/services/confirm-dialog/confirm-dialog.service'

interface StandardizedErrorItem {
  code: string
  detail: string
  attr: string
}

interface StandardizedError {
  type: 'validation_error' | 'server_error' | 'client_error'
  errors: StandardizedErrorItem[]
  message?: string
}

interface AppErrorResponse extends HttpErrorResponse {
  error: StandardizedError
}

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private confirmDialogService: ConfirmDialogService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.handleReq(req, next)
  }

  handleReq(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: AppErrorResponse) => {
        if (this.toastService.isErrorShouldBeSuppressed(req.url)) {
          // Suppressed all errors from this url
          return EMPTY
        }

        if (!navigator.onLine || error.error?.message === 'Failed to fetch') {
          this.handleOffline()
          return EMPTY
        }
        const {
          error: { type, errors },
        } = error

        if (type === 'server_error' && !this.toastService.suppressErrorType.includes('server_error')) {
          this.handleServerError(errors)
          return EMPTY
        }

        return throwError(() => error)
      }),
    )
  }

  getFirstErrorCode(errors: StandardizedErrorItem[]) {
    if (!errors) {
      return ''
    }
    return errors.length > 0 && errors[0]?.code ? errors[0]?.code : ''
  }

  handleServerError(errors: StandardizedErrorItem[]) {
    this.confirmDialogService.open({
      title: 'พบข้อผิดพลาดบางอย่าง',
      description: errors[0].detail,
      buttonText: 'ลองใหม่',
      icon: 'cancelRed',
      closeCallback: () => {
        location.reload()
      },
    })
  }

  handleOffline() {
    this.confirmDialogService.open({
      title: 'ไม่สามารถเชื่อมต่อได้',
      description: 'เกิดข้อผิดพลาดบางอย่าง หรือเกิดปัญหา\nการเชื่อมต่อ กรุณาลองใหม่อีกครั้ง',
      buttonText: 'ลองใหม่',
      icon: 'networkManage',
      closeCallback: () => {
        location.reload()
      },
    })
  }
}

import { Component, HostListener, inject, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterOutlet } from '@angular/router'
import { CookieConsentModalComponent } from '@pages/tos-page/cookie-consent-modal/cookie-consent-modal.component'
import { DeviceOrientationService } from '@service/device-orientation.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [CommonModule, RouterOutlet, CookieConsentModalComponent],
})
export class AppComponent implements OnInit {
  private readonly deviceOrientationService = inject(DeviceOrientationService)

  protected isPortrait = this.deviceOrientationService.isPortrait

  title = 'jv-b2b-frontend'

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    this.isPortrait.set(!this.isPortrait())
  }

  ngOnInit() {
    this.isPortrait.set(window.screen.orientation.type.includes('portrait'))
  }
}

import { Component, computed, inject } from '@angular/core'
import { Router } from '@angular/router'
import { CookieConsentService } from '@service/cookie-consent.service'
import { ConfirmModalComponent } from '@shared/components/v2/core/modal/confirm-modal/confirm-modal.component'
import { GtWsService } from '@core/services/gt-ws/gt-ws.service'
import { UserDataService } from '@core/services/user-data/user-data.service'

@Component({
  selector: 'app-cookie-consent-modal',
  imports: [ConfirmModalComponent],
  templateUrl: './cookie-consent-modal.component.html',
})
export class CookieConsentModalComponent {
  private readonly router = inject(Router)
  private readonly cookieConsentService = inject(CookieConsentService)
  private readonly userDataService = inject(UserDataService)
  private readonly gtWsService = inject(GtWsService)

  protected shownCookieConsentModal = this.cookieConsentService.shownCookieConsentModal

  protected isMultiWs = computed(() => {
    return (this.userDataService.userData()?.myGtWholesaleIds ?? []).length > 1
  })

  protected isNewDesign = computed(() => {
    if (this.userDataService.userData()) {
      const ws = this.userDataService
        .userData()!
        .myGtWholesaleIdsShowNewDesign.find((ws) => ws['id'] === this.gtWsService.wsId)
      return !!ws?.['isShowNewDesign']
    }
    return false
  })

  goToCookieSetting() {
    this.cookieConsentService.setShownCookieConsentModal(true)
    if (this.gtWsService.wsId) {
      if (this.isNewDesign()) {
        return this.router.navigate(['general-trade', 'tos', 'cookie-settings'])
      } else if (!this.isMultiWs()) {
        return this.router.navigate(['gt', 'tos', 'cookie-settings'])
      }
    }
    if (this.isMultiWs()) {
      this.gtWsService.clearWsId()
    }
    return this.router.navigate(['gt', 'home', 'tos', 'cookie-settings'])
  }

  protected handleConfirm() {
    this.cookieConsentService.acceptAllCookies()
  }
}

import { CanActivateFn, Router } from '@angular/router'
import { inject } from '@angular/core'
import { UserDataService } from '@core/services/user-data/user-data.service'
import { Me, RoleEnum, WholesaleStatusEnum } from '@core/api'
import { filter } from 'rxjs'

export const AuthWsGuard: CanActivateFn = () => {
  const userDataService = inject(UserDataService)
  const router = inject(Router)

  return new Promise((resolve) => {
    userDataService.userData$.pipe(filter((userData): userData is Me => !!userData)).subscribe((meData) => {
      if (
        meData.role === RoleEnum.Ws &&
        (meData.myWholesaleStatus === WholesaleStatusEnum.Approved ||
          meData.myWholesaleStatus === WholesaleStatusEnum.TemporaryClosed)
      ) {
        router.navigate(['ws']).then(() => {
          resolve(false)
        })
        return
      }
      return resolve(true)
    })
  })
}
